<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import { getAction, postAction, putAction } from '../../../command/netTool'
import { subMerTypeList, certificateTypeList, companyCertTypeList, accountTypeList } from '@/utils/textFile'

export default {
  name: 'secondaryDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        certificateNo: null,
        companyCertNo: null,
        shopId: null,
        subMerType: '1'
      },
      storeData: [],
      bankList: []
    }
  },
  watch: {
    'detail.shopId'(a, b) {
      if (a) {
        getAction(`/api/commodity/farmMainBody/firmInfo/${a}`).then(res => {
          if (res.code == 200) {
            this.detail.companyCertNo = res.data.xydm
            this.$forceUpdate()
          } else {
            this.$message.warning(res.msg)
          }
        })
      } else {
        this.detail.shopId = null
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail.call(this, {
        url: `/commodity/farmShopSubMerchant/detail/${id}`
      })
    }

    // if (!id) {
    //   getAction('/api/commodity/farmShopSubMerchant/getSubMerNo').then(res => {
    //     if (res.code == 200) {
    //       this.detail.subMerId = res.data.subMerNo
    //     } else {
    //       this.$message.warning(res.msg)
    //     }
    //   })
    // }

    this.storeSearch()
    this.storeSearch2()
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    async storeSearch2(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopMerchantBank/list?bankName=${value}`
      })
      this.bankList = searchResult.map(el => {
        return {
          name: el.bankName,
          value: el.bankName
        }
      })
    },
    onProductMenuClick(data) {
      let { value } = data
      getAction(`/api/firm/${value}`).then(res => {
        let { fddbr, firmName, xydm } = res.data
        let { contactName, companyName, companyCertNo } = this.detail
        this.detail = {
          ...this.detail,
          contactName: fddbr ?? contactName,
          companyName: firmName ?? companyName,
          companyCertNo: xydm ?? companyCertNo
        }
        this.$forceUpdate()
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          //   {
          //     name: '二级商户编号',
          //     type: 'input',
          //     cols: 12,
          //     key: 'subMerId',
          //     rules: [
          //       {
          //         required: true,
          //         type: 'string'
          //       }
          //     ]
          //   },
          {
            name: '关联主体',
            type: 'dropDownInput',
            cols: 12,
            key: 'shopName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.onProductMenuClick,
            valueKey: 'shopId',
            inputProps: {
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true,
                message: '请选择关联主体'
              }
            ]
          }
          //   {
          //     name: '二级商户名',
          //     type: 'input',
          //     cols: 12,
          //     key: 'subMerName',
          //     rules: [
          //       {
          //         required: true,
          //         type: 'string'
          //       }
          //     ]
          //   }
        ]
      }
    },
    getForm2() {
      return {
        title: '法人信息',
        type: 'cardForm',
        data: [
          {
            name: '法定代表人',
            type: 'input',
            cols: 12,
            key: 'contactName'
          },
          {
            name: '证件类型',
            type: 'select',
            cols: 12,
            key: 'certificateType',
            defaultValue: 'I',
            typeData: certificateTypeList
          },
          {
            name: '证件号码',
            type: 'input',
            cols: 12,
            key: 'certificateNo'
          }
        ]
      }
    },
    handleGet3() {
      return [
        // {
        //   name: '个人名字',
        //   type: 'input',
        //   cols: 12,
        //   key: 'companyName',
        //   display: this.detail.subMerType == '1'
        // },
        {
          name: '企业名字',
          type: 'input',
          cols: 12,
          key: 'companyName'
          // display: this.detail.subMerType != '1'
        },
        {
          name: '证件类型',
          type: 'select',
          cols: 12,
          key: 'companyCertType',
          defaultValue: 'V',
          typeData: companyCertTypeList
          // display: this.detail.subMerType != '1'
        },
        {
          name: '证件号码',
          type: 'input',
          cols: 12,
          key: 'companyCertNo'
          // display: this.detail.subMerType != '1'
        }
      ]
      // .filter(e => e.display)
    },
    getForm3() {
      let _this = this
      return {
        title: '商户信息',
        type: 'cardForm',
        data: [
          {
            name: '商户类型（选择个人不需要填写相关信息）',
            type: 'radioButton',
            cols: 12,
            key: 'subMerType',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ],
            typeData: subMerTypeList
          },
          ..._this.handleGet3()
        ]
      }
    },
    getForm4() {
      return {
        title: '收款信息',
        type: 'cardForm',
        data: [
          {
            name: '收款账户（选择企业必须用对公账户）',
            type: 'radioButton',
            cols: 12,
            key: 'accountType',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ],
            typeData: accountTypeList
          },
          {
            name: '收款银行',
            type: 'select',
            cols: 12,
            key: 'bankName',
            typeData: this.bankList,
            props: {
              showSearch: true
            }
          },
          {
            name: '名字',
            type: 'input',
            cols: 12,
            key: 'accountName'
          },
          {
            name: '卡号',
            type: 'input',
            cols: 12,
            key: 'account'
          },
          {
            name: '银行预留手机号',
            type: 'input',
            cols: 12,
            key: 'mobilePhone'
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: `/commodity/farmShopSubMerchant/${!this.detail.id ? 'add' : 'update'}`,
                params: {
                  ...data
                },
                isPost: false
              })
              .then(() => {
                this.$router.push('/marketingCenter/secondary')
              })
          }
        },
        {
          name: '取消',
          onClick: () => {
            this.$router.push('/marketingCenter/secondary')
          }
        }
      ]

      const right = [
        {
          name: '删除',
          type: 'danger',
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/commodity/farmShopSubMerchant/delete/${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/secondary')
            }, 500)
          }
        }
      ]

      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          shopName: this.storeData
        }}
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4()]}
        havedrag={true}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
/deep/ .ant-table-content {
  padding: 0 20px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_box {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 25px;
  max-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
